import { PayProps } from '../../../../models/bookAService'
import paypal from '../../../../assets/pics/bookAService/paypal.svg'
import visa from '../../../../assets/pics/bookAService/visa.svg'
import cash from '../../../../assets/pics/bookAService/cash.svg'

import './pay.css'
import toast from 'react-hot-toast'

function Pay(props:PayProps) {
  const {setStep, setAppointment, appointment} = props
  const paymentMethods = [
    { id: 'paypal-1', name: 'Paypal', icon: <img src={paypal} alt='icon'/> },
    { id: 'visa', name: 'Visa card', icon: <img src={visa} alt='icon'/> },
    { id: 'cash', name: 'Cash', icon: <img src={cash} alt='icon'/> },
  ]
  return (
    <div className='pay step hideScrollBar'>
      <h4 className='importantText'>Choose your payment method</h4>

      <div className="payRadioGroup">
          {paymentMethods.map((method) => (
            <label
              htmlFor={`paymentMethod-${method.id}`}
              key={method.id}
            >
              <div className="paymentMethodInfo">
                {method.icon}
                <span>{method.name}</span>
              </div>
              <input
                id={`paymentMethod-${method.id}`}
                type="radio"
                name="paymentMethod"
                value={method.id}
                checked={appointment.paymentMethod === method.id}
                onChange={() => setAppointment(pre => {return{...pre, paymentMethod: method.id}})}
              />
            </label>
          ))}
        </div>

      <button
        className="confirmButton"
        onClick={() => {
          if(!appointment.paymentMethod)
            toast.error('Choose a payment method', {duration: 3000})
          else {
            toast.success('Your appointment has been successfully registered', {duration: 3000})
            setStep(pre => pre+1)
          }
        }}
      >
        Confirm
      </button>
    </div>
  )
}

export default Pay