import StarRating from '../starRating/StarRating'
import avatar from '../../../../assets/pics/homepage/avatar.webp'
import './ratingCard.css'

function RatingCard() {
  return (
    <div className='ratingCard'>
      <div className='rater'>
        <div>
          <img src={avatar} alt='avatar'/>
          <span>Ahmad Alhasany</span>
        </div>
        <p>11 months</p>
      </div>
      <p>
        Lorem ipsum dolor sit amet consectetur. Ut venenatis nisl lectus posuere libero. Elementum gravida integer turpis euismod nulla mi.
      </p>
      <div className='stars'>
        <StarRating rating={4} h={'18'} w={'18'}/>
        <p>4</p>
      </div>
    </div>
  )
}

export default RatingCard