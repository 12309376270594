import AppBarPC from '../appBar/AppBarPC'
import AppBarMo from '../appBar/AppBarMo'
import { Outlet } from 'react-router-dom'
import ScrollToTop from '../../../utils/functions/ScrollToTop'

function Layout() {
  return (
    <>
        <AppBarPC/>
        <AppBarMo/>
        <ScrollToTop/>
        <Outlet/>
    </>
  )
}

export default Layout