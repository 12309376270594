export function validateEmail(email: string): boolean {
  return /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(email);
}
  
export function validatePassword(password: string): boolean {
  return password.length >= 8;
}

export function validateConfirmPassword(password:string, confirmPassword:string){
  return password === confirmPassword
}

export function validateName(name: string): string {
  if (name.length < 3) {
    return 'Name too short';
  }
  else if(name.length > 255){
    return 'Name too long'
  }
  else if(!/^[a-zA-Z0-9\u0600-\u06FF_ -]+$/.test(name)){
    return 'Name must consist of letters, numbers, - or _ only';
  }

  return ''
}

export function validateOTP(code:string[]){
  return code.every(el => el!=="")
}