import CustomButton from '../nestedComponents/customButton/CustomButton';
import cake from '../../../assets/pics/auth/cake.svg'
import { toast} from 'react-hot-toast';
import { ExtraInfoProps } from '../../../models/auth/auth';
import { useState } from 'react';
import './extraInfo.css'
import BloodTypeSelect from '../nestedComponents/bloodTypeSelect/BloodTypeSelect';

function ExtraInfo(props: ExtraInfoProps) {
  const { setPage } = props;
  const [formData, setFormData] = useState({
    datePicker: '',
    bloodtype_id:''
  });

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement> | { name: string; value: string; }) {
    const {name, value} = 'target' in e ? e.target : e
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  function handleSubmit(){
    setPage(pre => pre+1)
  }

  return (
    <div className='page'>
      <h3 className='authH3'>Record information</h3>
      <div className='form h-[330px]'>
        <div>
          <div className='textInputsWra'>
            <BloodTypeSelect formData={formData} handleChange={handleInputChange}/>
            <div>
              {false && <p className="errorText">{false}</p>}
                <div className="input">
                    <img src={cake} alt='lock' className='icon' />
                    <input
                        type="date"
                        name='datePicker'
                        id='datePicker'
                        placeholder="Date of birth"
                        value={formData.datePicker}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

          </div>
        </div>
        <CustomButton
          content='Sing up'
          disabled={!(formData.bloodtype_id && formData.datePicker)}
          action={() =>handleSubmit()}
        />
      </div>
    </div>
  );
}

export default ExtraInfo;