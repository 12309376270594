import { configureStore } from "@reduxjs/toolkit";
import signupReducer from '../features/signupSlice/signupSlice'
import verifySignupReducer from "../features/verifySignupSlice/verifySignupSlice";
import loginReducer from '../features/loginSlice/loginSlice'
import verifyForgotPasswordReducer from '../features/verifyForgotPasswordSlice/verifyForgotPasswordSlice'
import resetPasswordReducer from '../features/resetPasswordSlice/resetPasswordSlice'

export const store = configureStore({
    reducer:{
        signup: signupReducer,
        verifySignup: verifySignupReducer,
        login: loginReducer,
        verifyForgotPassword: verifyForgotPasswordReducer,
        resetPassword: resetPasswordReducer,
    }
})

export type Rootstore = ReturnType<typeof store.getState>
export type Dispatch = typeof store.dispatch