import { Link } from 'react-router-dom'
import hospital from '../../../../assets/pics/homepage/hospital.webp'
import star from '../../../../assets/pics/homepage/star.svg'
import './providerCard.css'

function ProviderCard() {
  return (
    <div className='providerCard'>
      <img src={hospital} alt='provider'/>
      <div className='providerCardColFlex'>
        <div className='providerCardText'>
          <h4>Almojtahed hospital</h4>
          <div className='providerRating'>
            <img src={star} alt='star'/>
            4.3
            <p>(123 reviews)</p>
          </div>
          <div className='providerServices'>
            <span className="providerService">Some service</span>
            <span className="providerService">Other service</span>
          </div>
        </div>
        <Link to='/ourservices/service/2?DI=3' className='bookServiceLink'>Book service</Link>
      </div>
    </div>
  )
}

export default ProviderCard