import { Link } from 'react-router-dom'
import save from '../../../../assets/pics/save.svg'
import { importantBlogProps } from '../../../../models/blog'
import './importantBlog.css'

function ImportantBlog(props: importantBlogProps) {
  const {buttonContent, image} = props

  return (
    <div className='importantBlog'>
        <div className='importantBlogPicSec'>
          <button className="saveButton">
            <img src={save} alt='save' className='save'/>
          </button>
          <img src={image} alt='pic' className='importantBlogPic'/>
          <Link to='/blog/1'>{buttonContent}</Link> 
        </div>
        <div className="importantBlogDes">
          <span className='secondaryTitle'>Lorem ipsum dolor sit amet consectur. </span>
          <p>Lorem ipsum dolor sit amet consectetur. Neque aliquet diam egestas iaculis fames ornare. Arcu tempus eu id pulvinar mattis. Adipiscing consequat aliquam eleifend ac. Faucibus sociis euismod vitae euismod dictum integer in metus aliquet. Congue sed diam eget pellentesque.</p>
          <p className='marginY'>12 / 5 / 2024  By Dr. Reda Al-kweifati</p>
        </div>
    </div>
  )
}

export default ImportantBlog