import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VerifyForgotPasswordState, VerifyForgotPasswordAction } from "../../../models/auth/RTKAuth";
import { postData } from "../../../api/crudServices";

const initialState:VerifyForgotPasswordState = {
    loading: false,
    sendError: '',
    verifyError: '',
}

export const verifyForgotPasswordRequest = createAsyncThunk(
    'verifyForgotPassword/verifyForgotPasswordRequest', 
    async ({email, code}:VerifyForgotPasswordAction , { rejectWithValue }) => {
        try {
            const res = await postData('user/verify_password', { email, code });
            return res.data;            
        } catch (error: any) {
            return rejectWithValue(error?.response?.data?.error || 'Something went wrong');
        }
    }
);


export const sendForgotPasswordRequest = createAsyncThunk(
    'verifyForgotPassword/sendForgotPasswordRequest', 
    async (email:string, { rejectWithValue }) => {
        try {
            const res = await postData('user/send_password', { email });   
            return res.data;            
        } catch (error: any) {
            return rejectWithValue(error?.response?.data?.error || 'Something went wrong');
        }
    }
);

const verifyForgotPassword = createSlice({
    name:'verifyForgotPassword',
    initialState,
    reducers: {},
    extraReducers:(builder) => {
        builder.addCase(sendForgotPasswordRequest.pending, (state) => {
            state.loading = true;
            state.sendError = '';
            state.verifyError = '';
        });

        builder.addCase(sendForgotPasswordRequest.fulfilled, (state) => {
            state.loading = false;
            state.sendError = '';
            state.verifyError = '';
        });
          

         builder.addCase(sendForgotPasswordRequest.rejected, (state, action) => {
            state.loading = false;
            state.verifyError = '';
            state.sendError = action.payload as string || "Something went wrong"
        });

        builder.addCase(verifyForgotPasswordRequest.pending, (state) => {
            state.loading = true;
            state.sendError = '';
            state.verifyError = '';
        });

        builder.addCase(verifyForgotPasswordRequest.fulfilled, (state, action) => {
            state.loading = false;
            state.verifyError = '';
            state.sendError = '';
        
            sessionStorage.removeItem('authToken');
            localStorage.setItem('authToken', action.payload.token);
        });

         builder.addCase(verifyForgotPasswordRequest.rejected, (state, action) => {
            state.loading = false;
            state.sendError = '';
            state.verifyError = action.payload as string || "Something went wrong"
        });       
    }

})

export default verifyForgotPassword.reducer