import ImportantBlog from '../nestedComponents/importantBlog/ImportantBlog'
import food from '../../../assets/pics/homepage/food.webp'
import save from '../../../assets/pics/save.svg'
import './mostImportantBlogs.css'
import { Link } from 'react-router-dom'


function MostImportantBlogs() {
  const buttonContent = 'Read more';
  return (
    <div className='mostImportantBlogs'>
      <h2 className='header2'>Most important blogs</h2>
      <p className='secIntro'>Lorem ipsum dolor sit amet consectetur. Faucibus morbi elementum sollicitudin dignissim.</p>
      <div className='importantBlogs w-full'>
        <div className='bigImportantBlog'>
          <button className='saveButton'>
            <img src={save} alt='save' className='save' />
          </button>
          <img src={food} alt='img' className='bigPic'/>
          <div className='bigDes'>
            <span className='secondaryTitle marginY inline-block'>Lorem ipsum dolor sit amet consectur. </span>
            <p>Lorem ipsum dolor sit amet consectetur. Neque aliquet diam egestas iaculis fames ornare. Arcu tempus eu id pulvinar mattis. Adipiscing consequat aliquam eleifend ac. Faucibus sociis euismod vitae euismod dictum integer in metus aliquet. Congue sed diam eget pellentesque. Egestas magna curabitur facilisis molestie vel auctor ac mauris varius. Cras ut eu nunc molestie. Enim est.</p>
            <p className='marginY'>12 / 5 / 2024  By Dr. Reda Al-kweifati</p>
            <Link to='/blog/2'>Read more</Link>
          </div>
        </div>
        <ImportantBlog image={food} buttonContent={buttonContent}/>
        <ImportantBlog image={food} buttonContent={buttonContent}/>
        <ImportantBlog image={food} buttonContent={buttonContent}/>
      </div>
    </div>
  )
}

export default MostImportantBlogs