
import { useState, useRef, useEffect } from 'react';
import { DropdownMenuProps } from '../../../models/AppBar';
import blueDownArrow from '../../../assets/pics/appBar/blueDownArrow.svg'
import { Link, useNavigate } from 'react-router-dom';
import avatar from '../../../assets/pics/blog/person.png'
import './dropDown.css'

function DropDown(props:DropdownMenuProps) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const {userName} = props, navigate = useNavigate();
    
    useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) 
            setIsOpen(false);
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    }, []);
        
    return (
        <div className="dropDown" ref={dropdownRef}>
            <button onClick={() => setIsOpen(!isOpen)}>
                <img src={avatar} alt="pic" className='pic'/>
                <span className='mr-2'>{userName}</span>
                <svg height='20' width='20' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
            </button>
    
            {isOpen && (
                <div className="dropDownMenu">
                    <Link to="/profile">Profile </Link>
                    <div className="px-4 py-2">
                    <button
                        onClick={() => {
                            navigate('/login')
                            localStorage.removeItem('authToken')
                        }}
                    >
                        Logout
                    </button>
                    </div>
                </div>
            )}
        </div>
    );
};    
export default DropDown