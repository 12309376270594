import bloodType from '../../../assets/pics/profile/bloodType.svg'
import calendar from '../../../assets/pics/calendar.svg'
import profilePic from '../../../assets/pics/profilePic.webp'
import './profileInfo.css'

function ProfileInfo() {
  return (
    <div className='profileInfo paper'>
        <img src={profilePic} alt="profilePic" />
        <div className='medicalRecord'>
            <div className='patientName'>
                <h2 className='header2'>Ahmad Alhasany</h2>
                <h4>3/6/2004</h4>
            </div>
            <p className=''>+963 954 179 357</p>
            <div className='medicalInfo'>
                <div>
                    <img src={bloodType} alt="icon" className='iconWithBlueBg'/>
                    <span>Blood type: A+</span>
                </div>
                <div>
                    <img src={calendar} alt="icon" className='iconWithBlueBg'/>
                    <span>Last medical check: 16 / 10 / 2024</span>
                </div>
            </div>
            <p className='patientStatus whitespace-pre-wrap'>
                {`Lorem ipsum dolor sit amet consectetur. Diam in nisi sed facilisi ac praesent sed pharetra. Non odio morbi justo semper mollis lectus quis massa. Sed lectus mattis tortor purus sollicitudin. Ut sagittis ultricies libero ultrices sed dignissim at at vivamus. Tortor ante ac accumsan sit in in. Vel diam convallis id in amet volutpat mattis maecenas nec. Suscipit aliquam lectus eleifend massa. Etiam amet cursus dictum et.
Fermentum lectus purus tellus blandit risus donec. Nunc convallis pellentesque ultrices ac faucibus.`}
            </p>
        </div>
    </div>
  )
}

export default ProfileInfo