import JobCard from '../../components/applyForAJob/jobCard/JobCard'
import './applyForAJob.css'

function ApplyForAJob() {
  return (
    <div className='applyForAJobWra'>
      <div className='applyForAJob paper'>
        <h3 className='blueBox'>Apply for a job</h3>
        <div className='centeredText'>
          <h2 className='header2'>select the section you want to work in</h2>
          <p className='secIntro'>
            Lorem ipsum dolor sit amet consectetur. Faucibus morbi elementum sollicitudin dignissim.
          </p>
        </div>    
        <div className='jobsWra'>
          <JobCard/>
          <JobCard/>
          <JobCard/>
          <JobCard/>
          <JobCard/>
          <JobCard/>
          <JobCard/>
          <JobCard/>
        </div>
      </div>
    </div>
  )
}

export default ApplyForAJob