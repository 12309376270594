import React from 'react';
import { TimerProps } from '../../../../models/auth/auth';

function Timer(props: TimerProps) {
    const {requestOTP} = props
    const [timer, setTimer] = React.useState(20);
    const [canResend, setCanResend] = React.useState(false);

    React.useEffect(() => {
        if (timer === 0) {
            setCanResend(true);
            return;
        }

        const interval = setInterval(() => {
            setTimer((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [timer]);

    const handleResend = () => {
        requestOTP();
        setTimer(20);
        setCanResend(false);
    };

    return (
        <>
            {!canResend ? 
                <span className="orangeUnderline">
                    {`0:${timer < 10 ? `0${timer}` : timer}`}
                </span>
             :(
                <button type="button" onClick={handleResend} className="resendButton orangeUnderline">
                    Resend Code
                </button>
            )}
        </>
    );
}

export default Timer;
