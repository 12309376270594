import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ResetPasswordAction, ResetPasswordState } from "../../../models/auth/RTKAuth";
import { postData } from "../../../api/crudServices";

const initialState:ResetPasswordState = {
    loading: false,
    error: '',
}

export const resetPasswordRequest = createAsyncThunk(
    'resetPassword/resetPasswordRequest', 
    async ({password, confirmPassword}:ResetPasswordAction, {rejectWithValue}) =>{
        try{
            const res = await postData('user/reset_password', {new_password: password, new_password_confirmation: confirmPassword})
            return res.data
        } catch(error: any){
            return rejectWithValue(error?.response?.data?.error || 'Something went wrong')
        }
    }
)

const resetPassword = createSlice({
    name: 'resetPassword',
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        (builder).addCase(resetPasswordRequest.pending, (state)=>{
            state.error = '';
            state.loading = true;
        });

        (builder).addCase(resetPasswordRequest.fulfilled, (state, action)=>{
            state.error = '';
            state.loading = false;

            const token = action.payload.token;
            localStorage.removeItem('authToken');
            sessionStorage.removeItem('authToken');
            
            if (localStorage.getItem('remember_me') === 'true')
                localStorage.setItem('authToken', token);
            else 
                sessionStorage.setItem('authToken', token);
            localStorage.removeItem('remember_me')
        
        });

        (builder).addCase(resetPasswordRequest.rejected, (state, action)=>{
            state.error = action.payload as string;
            state.loading = false;
        });
    }
})

export default resetPassword.reducer