import './checkInfo.css'
import profilePic from '../../../../assets/pics/profilePic.webp'
import clock from'../../../../assets/pics/bookAService/clock.svg'
import calendar from '../../../../assets/pics/calendar.svg'
import { CheckInfoProps } from '../../../../models/bookAService'

/*
In this step, you should've saved the info about the doctor in json string format
in the appointment state, here you take that info with day and time
show everything for the user so they can confirm the request
*/


function CheckInfo(props: CheckInfoProps) {
  const {setStep} = props
  return (
    <div className='checkInfo step hideScrollBar'>
      <img src={profilePic} alt='doctorPic'/>
      <div className='checkInfoDoctor'>
        <h4 className='importantText'>Dr. Reda Al-kweifati</h4>
        <p>Lorem ipsum dolor sit amet consectetur. Vitae</p>
      </div>
      <div className='checkInfoDate'>
        <h4 className='importantText'>Date and time</h4>
        <div className='dateDets'>
          <img src={calendar} alt="date" className='iconWithBlueBg'/>
          <span>sun 12 / 5 / 2024</span>
        </div>
        <div className='dateDets'>
          <img src={clock} alt="time" className='iconWithBlueBg'/>
          <span>9:00 AM - 10:00 AM</span>
        </div>
      </div>
      <div className='checkInfoButtons'>
        <button onClick={()=>setStep(0)} className='cancel'>Cancel</button>        
        <button onClick={()=>setStep(pre => pre+1)} className='confirm'>Confirm</button>        
      </div>
    </div>
  )
}

export default CheckInfo