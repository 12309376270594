import { ChangeEvent, useState } from 'react';
import BookingsCard from '../bookingsCard/BookingsCard'
import './onQueue.css'
import ChooseDate from '../../../bookAService/nestedComponents/chooseDate/ChooseDate';
import CheckInfo from '../../../bookAService/nestedComponents/checkInfo/CheckInfo';

function OnQueue() {
  const [openModalId, setOpenModalId] = useState(0);
  const [step, setStep] = useState(0);
  const [data, setData] = useState({ day:'', time:''});
  
  const handleOpenModal = (id:number) => {
    setOpenModalId(id);
  };
  const handleCloseModal = () => {
    setData({ day:'', time:''});
    setStep(0)
    setOpenModalId(0);
  };
  const displayedComponent = ()=> {
    switch(step){
      case 0: return <ChooseDate setStep={setStep} appointment={data} setAppointment={(e:{name:string, value:string})=>{
        setData(pre=>{return {...pre, [e.name]:e.value}})
      }}/>
      case 1: return <CheckInfo setStep={setStep}/>;
      default: handleCloseModal()
   }
  }
  return (
    <div className='completed bookingsSec'>
      {Array.from({ length: 4 }, (_, index) => {
        const id = index + 1;

        return (

          <BookingsCard key={index}>
            <div className='completedCheckInfoButtons'>
              <button className='cancel'>Cancel</button>        
              <button className='changeDate'
                onClick={() => handleOpenModal(id)}
              >Change Date</button>        
            </div>
            {openModalId === id && (
              <div className="modalBackdrop" onClick={handleCloseModal}>
                <div className="modal" onClick={(e)=>e.stopPropagation()}>
                  {displayedComponent()}
                </div>
              </div>
            )}
          </BookingsCard>
        )
      })}
    </div>
  )
}

export default OnQueue