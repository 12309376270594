import React, { useEffect } from 'react'
import PasswordInput from '../../nestedComponents/passwordInput/PasswordInput'
import CustomButton from '../../nestedComponents/customButton/CustomButton'
import { ResetPasswordFormType, ResetPasswordProps } from '../../../../models/auth/auth';
import { validateConfirmPassword, validatePassword } from '../../../../utils/functions/validation';
import { useAppDispatch, useAppSelector } from '../../../../RTK/hooks/hooks';
import toast from 'react-hot-toast';
import { resetPasswordRequest } from '../../../../RTK/features/resetPasswordSlice/resetPasswordSlice';

function ResetPassword(props:ResetPasswordProps) {
    const {setPage} = props
    const [formData, setFormData] = React.useState<ResetPasswordFormType>({ password: '', confirmPassword: '' });
    const [disabled, setDisabled] = React.useState(true);
    const [cPasswordError, setcPasswordError] = React.useState(false);
    const [passwordError, setPasswordError] = React.useState(false);

    const {loading, error} = useAppSelector((state)=>state.resetPassword);
    const dispatch = useAppDispatch();
    
    useEffect(()=>{
        if(error)
          toast.error(error, {duration: 3000})
      },[error])
      
    async function handleAction(){
        try{
            const res = await dispatch(resetPasswordRequest({...formData})).unwrap();
            toast.success('Password Reset Successfully', {duration: 3000});
            setPage(0);
        } catch{}
    }

    useEffect(()=>{
        const passwordValid = validatePassword(formData.password);
        const cPasswordValid = validateConfirmPassword(formData.password, formData.confirmPassword);
        if(passwordValid)
            setPasswordError(false);
        if(cPasswordValid)
            setcPasswordError(false);
        setDisabled(!(passwordValid && cPasswordValid))
    },[formData.password, formData.confirmPassword])

  return (
    <div className='authPaper'>
        <div className='page'>
            <h3 className='authH3'>Let's reset your password</h3>
            <div className='form h-[274px]'>
                <div className='textInputsWra'>
                    <PasswordInput
                        password={formData.password}
                        setPassword={(password) => setFormData(prev => ({ ...prev, password }))}
                        passwordError={passwordError}
                        setPasswordError={setPasswordError}
                    />
                    <PasswordInput
                        password={formData.confirmPassword}
                        confirmPassword={formData.password}
                        setPassword={(confirmPassword) => setFormData(prev => ({ ...prev, confirmPassword }))}
                        passwordError={cPasswordError}
                        setPasswordError={setcPasswordError}
                        confirm
                    />
                </div>
                <CustomButton
                content='Reset password'
                disabled={disabled || loading}
                action={() =>handleAction()}
                />
            </div>
        </div>
    </div>
  )
}

export default ResetPassword