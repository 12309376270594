import React, { useState } from 'react';
import formDoctor from '../../../assets/pics/profile/formDoctor.webp';
import './complain.css';

function Complain() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    complaint: '',
  });
  const isFormValid = !(formData.complaint && formData.email && formData.name && formData.phone);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name === 'phone' && !/^\+?\d*$/.test(value))
        return;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // form logic    
  };

  return (
    <div className="complain">
      <img src={formDoctor} alt="formPic" />
      <form className="formWra" onSubmit={handleSubmit}>
        <h2 className="complainHeader2">
          Got a complaint? <span>Tell Us</span>
        </h2>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Full name"
          value={formData.name}
          onChange={handleInputChange}
        />
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Your email"
          value={formData.email}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="phone"
          id="phone"
          placeholder="Phone number"
          value={formData.phone}
          onChange={handleInputChange}
        />
        <textarea
          name="complaint"
          id="complaint"
          placeholder="Write your complaint"
          value={formData.complaint}
          onChange={handleInputChange}
        />
        <button type="submit" disabled={isFormValid}
            title={isFormValid ? 'Please fill out the entire form before submitting.' : ''}
        >Send your complaint</button>
      </form>
    </div>
  );
}

export default Complain;
