import React from 'react';
import lock from '../../../../assets/pics/auth/lock.svg';
import eye from '../../../../assets/pics/auth/eye.svg';
import eyeSlash from '../../../../assets/pics/auth/eyeSlash.svg';
import { validateConfirmPassword, validatePassword } from '../../../../utils/functions/validation';
import { PasswordInputProps } from '../../../../models/auth/auth';

function PasswordInput(props: PasswordInputProps) {
  const { password, confirmPassword = '' ,setPassword, passwordError, setPasswordError, confirm=false } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    setPasswordError( confirm ? (!validateConfirmPassword(password, confirmPassword)) : (!validatePassword(event.target.value)) );
  }

  return (
    <div>
      {passwordError && <p className="errorText">{confirm ? 'Passwords don\'t match' : 'The password must be at least 8 characters long'}</p>}
      <div className="input">
        <img src={lock} alt='lock' className='icon' />
        <input
            type={showPassword ? "text" : "password"}
            value={password}
            onBlur={handleBlur}
            onChange={(e) => setPassword(e.target.value)}
            name='password'
            id={`password${confirm&&'_co'}`}
            placeholder="Password"
        />
        <button type="button" className="eye" onClick={() => setShowPassword(prev => !prev)}>
          <img src={showPassword ? eyeSlash : eye} alt="eye" />
        </button>
      </div>
    </div>
  );
}

export default PasswordInput;
