import ServiceCard from '../../components/sharedComponents/serviceCard/ServiceCard'
import food from '../../assets/pics/homepage/food.webp'
import patient from '../../assets/pics/homepage/patient.webp'
import TopWriters from '../../components/blog/topWriters/TopWriters'
import MostImportantBlogs from '../../components/blog/mostImportantBlogs/MostImportantBlogs'
import './blog.css'

function Blog() {
    const buttonContent = 'Read more'
  return (
    <div className='blog' id='blog'>
        <MostImportantBlogs/>
        <div className='latestNews'>
            <h2 className='header2'>Latest news</h2>
            <p className='secIntro'>Lorem ipsum dolor sit amet consectetur. Faucibus morbi elementum sollicitudin dignissim.</p>
            <div className='cardsWra text-start'>
                <ServiceCard buttonContent={buttonContent} image={patient} to='/blog/2'/>
                <ServiceCard buttonContent={buttonContent} image={food} to='/blog/2'/>
                <ServiceCard buttonContent={buttonContent} image={food} to='/blog/2'/>
                <ServiceCard buttonContent={buttonContent} image={patient} to='/blog/2'/>
                <ServiceCard buttonContent={buttonContent} image={food} to='/blog/2'/>
                <ServiceCard buttonContent={buttonContent} image={patient} to='/blog/2'/>
            </div>
        </div>
        <TopWriters/>
    </div>
  )
}

export default Blog