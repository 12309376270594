import BookingsCard from '../bookingsCard/BookingsCard'

function Cancelled() {
  return (
    <div className='bookingsSec'>
        <BookingsCard/>
        <BookingsCard/>
        <BookingsCard/>
        <BookingsCard/>
    </div>
  )
}

export default Cancelled