import './search.css'

function Search({mobile = false}:{mobile?:boolean}) {
  return (
    <div className='search'>
        {
          mobile &&
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.5917 19.5917C20.0473 19.1361 20.786 19.1361 21.2416 19.5917L26.4916 24.8417C26.9472 25.2973 26.9472 26.036 26.4916 26.4916C26.036 26.9472 25.2973 26.9472 24.8417 26.4916L19.5917 21.2416C19.1361 20.786 19.1361 20.0473 19.5917 19.5917Z" fill="#F7B217"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.16699 12.8332C1.16699 6.38985 6.39033 1.1665 12.8337 1.1665C19.2769 1.1665 24.5003 6.38985 24.5003 12.8332C24.5003 19.2764 19.2769 24.4998 12.8337 24.4998C6.39033 24.4998 1.16699 19.2764 1.16699 12.8332ZM12.8337 3.49984C7.679 3.49984 3.50033 7.67851 3.50033 12.8332C3.50033 17.9879 7.679 22.1665 12.8337 22.1665C17.9883 22.1665 22.167 17.9879 22.167 12.8332C22.167 7.67851 17.9883 3.49984 12.8337 3.49984Z" fill="#F7B217"/>
          </svg>
        }
        <input type="text" placeholder='Search...' className={`${mobile ? 'pl-12  h-[38px]': 'pl-4  h-[44px]'}`}/>
        {
          !mobile && <button>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fillRule="evenodd" clipRule="evenodd" d="M19.5917 19.5917C20.0473 19.1361 20.786 19.1361 21.2416 19.5917L26.4916 24.8417C26.9472 25.2973 26.9472 26.036 26.4916 26.4916C26.036 26.9472 25.2973 26.9472 24.8417 26.4916L19.5917 21.2416C19.1361 20.786 19.1361 20.0473 19.5917 19.5917Z" fill="white"/>
               <path fillRule="evenodd" clipRule="evenodd" d="M1.16699 12.8332C1.16699 6.38985 6.39033 1.1665 12.8337 1.1665C19.2769 1.1665 24.5003 6.38985 24.5003 12.8332C24.5003 19.2764 19.2769 24.4998 12.8337 24.4998C6.39033 24.4998 1.16699 19.2764 1.16699 12.8332ZM12.8337 3.49984C7.679 3.49984 3.50033 7.67851 3.50033 12.8332C3.50033 17.9879 7.679 22.1665 12.8337 22.1665C17.9883 22.1665 22.167 17.9879 22.167 12.8332C22.167 7.67851 17.9883 3.49984 12.8337 3.49984Z" fill="white"/>
            </svg>
          </button>
        }
    </div>
  )
}

export default Search