import { useParams } from 'react-router-dom'
import patient from '../../assets/pics/homepage/patient.webp'
import './bookAService.css'
import RatingWra from '../../components/bookAService/ratingWra/RatingWra'
import Booking from '../../components/bookAService/booking/Booking'

function BookAService() {
    const {id} = useParams()

    return (
    <div className='bookAService'>
        <img src={patient} alt="pic" />       
        <div className="servicedets">
            <RatingWra/>
            <Booking/>
        </div> 
    </div>
  )
}

export default BookAService