import Review from '../nestedComponents/review/Review'
import './rating.css'


function Rating({isWideScreen}:{isWideScreen:boolean}) {
    
  return (
    <div className='rating paper'>
        <h3 className='blueBox'>Rating</h3>
        <h2 className='header2'>Some of our customers’ reviews</h2>
        <p className='secIntro'>Lorem ipsum dolor sit amet consectetur. Faucibus morbi elementum sollicitudin dignissim.</p>
        <div className="cardsWra reviews">
            <Review/>
            <Review/>
            <Review/>
            {isWideScreen && (
                <>
                    <Review/>
                    <Review/>
                    <Review/>
                </>
            )}
        </div>
    </div>
  )
}

export default Rating