import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoginState, LoginAction } from "../../../models/auth/RTKAuth";
import { postData } from "../../../api/crudServices";

const initialState:LoginState = {
    loading: false,
    error: ''
}

export const loginRequest = createAsyncThunk(
    'login/loginRequest', 
    async ({ email, password, remember_me }: LoginAction, { rejectWithValue }) => {
        try {
            const res = await postData('user/login', { email, password });

            const token = res.data.token
            localStorage.removeItem('authToken');
            sessionStorage.removeItem('authToken');

            if (remember_me === 'true')
                localStorage.setItem('authToken', token);
            else
                sessionStorage.setItem('authToken', token);
            localStorage.removeItem('remember_me')
    
            return res.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.error || 'Something went wrong';
            return rejectWithValue(errorMessage);
        }
    }
);

const login = createSlice({
    name:'login',
    initialState,
    reducers: {},
    extraReducers:(builder) => {
        builder.addCase(loginRequest.pending, (state) => {
            state.loading = true;
            state.error = '';
        })

        builder.addCase(loginRequest.fulfilled, (state) => {
            state.loading = false;
            state.error = '';
        });
          

         builder.addCase(loginRequest.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string || "Something went wrong"
        })
    }

})

export default login.reducer