import { useEffect, useState } from 'react'
import { BloodTypeSelectProps, OptionType } from '../../../../models/auth/auth'
import CustomizableSelect from '../customizableSelect/CustomizableSelect'
import bloodCell from '../../../../assets/pics/auth/bloodCell.svg'


function BloodTypeSelect(props: BloodTypeSelectProps) {
    const {handleChange, formData} = props
    const [bloodTypes, setBloodTypes] = useState<OptionType[]>([{name:'A+', id: '1'}, {name:'A-', id: '2'}])

    /*useEffect(()=>{
        async function starter(){
            const res = await fetchCities()
            setBloodTypes(res)
        }
        starter()
    }, [])*/

  return (
    <CustomizableSelect options={bloodTypes} handleChange={handleChange} formData={formData} name='BloodType' icon={bloodCell}/>
  )
}

export default BloodTypeSelect