import { Outlet } from 'react-router-dom'
import Bookings from '../../components/profile/bookings/Bookings'
import ProfileInfo from '../../components/profile/profileInfo/ProfileInfo'
import './profile.css'
import Complain from '../../components/profile/complain/Complain'

function Profile() {
  return (
    <div className='profile'>
        <ProfileInfo/>
        <div className='bookings'>
          <Bookings/>
          <Outlet/>
        </div>
        <Complain/>
    </div>
  )
}

export default Profile