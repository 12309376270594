import ServiceCard from '../../components/sharedComponents/serviceCard/ServiceCard'
import food from '../../assets/pics/homepage/food.webp'
import blueStar from '../../assets/pics/ourServices/blueStar.svg'
import doctor from '../../assets/pics/ourServices/doctor.svg'
import group from '../../assets/pics/ourServices/group.svg'
import './ourServices.css'

function OurServices() {
    const buttonContent = 'Check it out'
  return (
    <div className='ourServices'>
        <div className='paper deaprtment'>
          <img src={food} alt="deaprtment" />
          <div className="departmentDes">
            <h2 className="secondaryTitle">Lorem ipsum dolor sit amet consectur. </h2>
            <p>Lorem ipsum dolor sit amet consectetur. Vitae velit purus dignissim at quam volutpat. Ac sagittis cursus rhoncus ut urna orci. Sit ac pellentesque nascetur tempor urna nec. Nibh a nullam sollicitudin et ac lacus. Volutpat nibh laoreet fames scelerisque elementum mauris ornare in neque.</p>
            <div className='numbers'>
              <div>
                <img src={group} alt="ic" />
                <span>+400 Appointment</span>
              </div>
              <div>
                <img src={doctor} alt="ic" />
                <span>+30 Professional doctors</span>
              </div>
              <div>
                <img src={blueStar} alt="ic" />
                <span>+4,2 Stars</span>
              </div>
            </div>
          </div>
        </div>
        <h2 className='header2'>Services of the section</h2>
        <p className='secIntro'>Lorem ipsum dolor sit amet consectetur. Faucibus morbi elementum sollicitudin dignissim.</p>
        <div className='cardsWra text-start'>
            <ServiceCard buttonContent={buttonContent} image={food} to='/ourservices/service/2'/>
            <ServiceCard buttonContent={buttonContent} image={food} to='/ourservices/service/2'/>
            <ServiceCard buttonContent={buttonContent} image={food} to='/ourservices/service/2'/>
            <ServiceCard buttonContent={buttonContent} image={food} to='/ourservices/service/2'/>
            <ServiceCard buttonContent={buttonContent} image={food} to='/ourservices/service/2'/>
            <ServiceCard buttonContent={buttonContent} image={food} to='/ourservices/service/2'/>
            <ServiceCard buttonContent={buttonContent} image={food} to='/ourservices/service/2'/>
            <ServiceCard buttonContent={buttonContent} image={food} to='/ourservices/service/2'/>
            <ServiceCard buttonContent={buttonContent} image={food} to='/ourservices/service/2'/>
            <ServiceCard buttonContent={buttonContent} image={food} to='/ourservices/service/2'/>
            <ServiceCard buttonContent={buttonContent} image={food} to='/ourservices/service/2'/>
            <ServiceCard buttonContent={buttonContent} image={food} to='/ourservices/service/2'/>
        </div>
    </div>
  )
}

export default OurServices