import mobileEq from '../../../assets/pics/homepage/mobileEq.webp'
import desktopEq from '../../../assets/pics/homepage/desktopEq.webp'
import { Link } from 'react-router-dom'
import './topSection.css'

function TopSection() {
  return (
    <div className='intro'>
        <div className='imWra'>
            <img src={mobileEq} alt='mobileEq' className='mobileEq'/>
            <img src={desktopEq} alt='desktopEq' className='desktopEq'/>
        </div>
        <div className='introTextWra'>
            <p className=''>
                With us <br/>
                <span> you are safe <br/></span>
                so don’t worry
            </p>
            <Link to='/' className='bgBlue'>our services</Link>
        </div>
    </div>
  )
}

export default TopSection