import WriterCard from '../nestedComponents/writerCard/WriterCard'
import './topWriters.css'

function TopWriters() {
  return (
    <div className='topWriters'>
      <h2 className='header2'>Top Writers</h2>
      <p className='secIntro'>Lorem ipsum dolor sit amet consectetur. Faucibus morbi elementum sollicitudin dignissim.</p>
      <div className='writerCardWra'>
        <WriterCard/>
        <WriterCard/>
        <WriterCard/>
        <WriterCard/>
      </div>
    </div>
  )
}

export default TopWriters