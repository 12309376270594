import React, { useEffect } from 'react';
import RemeberMe from '../../nestedComponents/rememberMe/RemeberMe';
import CustomButton from '../../nestedComponents/customButton/CustomButton';
import { LoginProps, LoginFormType } from '../../../../models/auth/auth';
import { Link } from 'react-router-dom';
import './login.css';
import PasswordInput from '../../nestedComponents/passwordInput/PasswordInput';
import EmailInput from '../../nestedComponents/emailInput/EmailInput';
import { validateEmail, validatePassword } from '../../../../utils/functions/validation';
import { useAppDispatch, useAppSelector } from '../../../../RTK/hooks/hooks';
import toast from 'react-hot-toast';
import { loginRequest } from '../../../../RTK/features/loginSlice/loginSlice';

function Login(props: LoginProps) {
  const { setPage, setEmail, email } = props;
  const [formData, setFormData] = React.useState<LoginFormType>({ password: '', remember_me: 'false' });
  const [disabled, setDisabled] = React.useState(true);
  const [emailError, setEmailError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);

  const dispatch = useAppDispatch()
  const {loading, error} = useAppSelector((state)=> state.login)
  
  useEffect(()=>{
    if(error)
      toast.error(error, {duration: 3000})
  },[error])
  
  async function handleLogin(){
    try{
      const res = await dispatch(loginRequest({email, ...formData})).unwrap()
      toast.success('Login successful!', {duration: 3000});
      setPage(0)
    } catch {}
  } 

  React.useEffect(() => {
    const emailValid = validateEmail(email);
    const passwordValid = validatePassword(formData.password);
    if(emailValid)
      setEmailError('');
    if(passwordValid)
      setPasswordError(false);
    setDisabled(!(emailValid && passwordValid));
  }, [email, formData.password]);

  return (
    <div className='page'>
      <h3 className='authH3'>Get full access to the web</h3>
      <div className='form h-[330px]'>
        <div>
          <div className='textInputsWra'>
            <EmailInput
              email={email}
              setEmail={setEmail}
              setEmailError={setEmailError}
              emailError={emailError}
            />
            <PasswordInput
              password={formData.password}
              setPassword={(password) => setFormData(prev => ({ ...prev, password }))}
              passwordError={passwordError}
              setPasswordError={setPasswordError}
            />
          </div>
          <div className='flexWra'>
            <RemeberMe handleChange={(e) => {
              const checked = e.target.checked?'true':'false'
              setFormData(prev => ({ ...prev, remember_me: checked }))
              localStorage.setItem('remember_me', checked)
              }} 
              remember_me={formData.remember_me} 
            />
            <button type="button" className='forgotPass' onClick={() =>{
              if(validateEmail(email))
                setPage(prev => prev + 1);
              else {
                setEmailError('Enter your email first')
              }
            }}>Forgot password?</button>
          </div>
        </div>
        <CustomButton
          content='Log in'
          disabled={disabled || loading}
          action={() => handleLogin()}
        />
      </div>
      <p className='DontHaveAccount'>
        Don't have an account? <Link className='orangeUnderline' to='/register'>create one</Link>
      </p>
    </div>
  );
}

export default Login;
