import Select, { StylesConfig } from 'react-select';
import { CustomizableSelectOptionType, CustomizableSelectProps } from '../../../../models/auth/auth';

function CustomizableSelect(props: CustomizableSelectProps) {
  const { options, formData, handleChange, name, icon, error, setError } = props;
  const dodgerBlue = '#5CA2FB', softGray = '#F7F7F7', lightGray = '#F2F2F2', darkGray = '#404040';

  const selectOptions = options.map(option => ({ label: option.name, value: option.id }));

  const customStyles: StylesConfig<CustomizableSelectOptionType> = {
    control: (provided) => ({
      ...provided,
      backgroundColor: softGray,
      borderColor: 'transparent',
      border: '2px solid transparent',
      borderRadius: '8px',
      outline: 'black',
      boxShadow: 'none',
      height: '46px',
      width: '100%',
      paddingLeft: '29px',
      fontSize: '16px',
      ':hover':{
        border: '2px solid transparent',
      },
      ':focus-within':{
        border: '2px solid black'
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#9CA3AF',
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%',
      backgroundColor: lightGray,
      marginTop: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected || state.isFocused ? dodgerBlue : 'transparent',
      color: state.isSelected || state.isFocused ? 'white' : '#16495f',
      padding: '10px 15px',
      fontSize: '16px',
      ':active': {
        backgroundColor: `${dodgerBlue}`,
        color:'white'
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: darkGray,
      cursor: 'pointer',
      paddingRight: '4px',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '150px',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    }),
  };

  const handleSelectChange = (selectedOption: CustomizableSelectOptionType) => {
    const lowerCaseName = name.toLowerCase()+'_id'
    if (selectedOption) {
      handleChange({ name:lowerCaseName , value: selectedOption.value });
    }
  };

  return (
    <div>
      {error && <p className="errorText">{error}</p>}
      <div className='input'>
        <Select
          options={selectOptions}
          styles={customStyles}
          isSearchable={true}
          placeholder={name}
          value={selectOptions.find(option => option.value === formData[name.toLowerCase()+'_id']) || null}
          onChange={(selected)=>handleSelectChange(selected as CustomizableSelectOptionType)}
          onBlur={()=>{formData[name.toLowerCase()+'_id'] && setError ? setError('') : setError && setError('This field is required')}}
        />
        <img src={icon} alt='info' className='icon'/>
      </div>
    </div>
  );
}

export default CustomizableSelect;
