import React from 'react'
import location from '../../../assets/pics/appBar/location.svg'
import downArrow from '../../../assets/pics/appBar/downArrow.svg'

function Location() {
  return (
    <div className='miniFlex'>
        <img src={location} alt="ic"/>
        Damascus, Syria
        <button>
          <img src={downArrow} alt="ic"/>
        </button>
    </div>
  )
}

export default Location