import DepartmentInfo from '../../components/applyForm/departmentInfo/DepartmentInfo'
import JobForm from '../../components/applyForm/jobForm/JobForm'
import './applyForm.css'

function ApplyForm() {
  return (
    <div className='applyForm'>
      <div className="paper">
        <div className='jobHeader'>
          <h3 className='blueBox'>Apply for a job</h3>
          <div className='centeredText'>
            <h2 className='header2'>select the section you want to work in</h2>
            <p className='secIntro'>
              Lorem ipsum dolor sit amet consectetur. Faucibus morbi elementum sollicitudin dignissim.
            </p>
          </div>
        </div>
        <DepartmentInfo/>
        <JobForm/>
      </div>
    </div>
  )
}

export default ApplyForm