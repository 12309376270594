import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VerfiySignupState, VerifySignupAction } from "../../../models/auth/RTKAuth";
import { postData } from "../../../api/crudServices";

const initialState:VerfiySignupState = {
    loading: false,
    sendError: '',
    verifyError: '',
}

export const verifySignupRequest = createAsyncThunk(
    'verifySignup/verifySignupRequest', 
    async ({email, code}:VerifySignupAction , { rejectWithValue }) => {
        try {
            const res = await postData('user/verify_otp', { email, code });
            return res.data;            
        } catch (error: any) {
            return rejectWithValue(error?.response?.data?.error || 'Something went wrong');
        }
    }
);


export const sendSignupRequest = createAsyncThunk(
    'verifySignup/sendSignupRequest', 
    async (email:string, { rejectWithValue }) => {
        try {
            const res = await postData('user/send_otp', { email });   
            return res.data;            
        } catch (error: any) {
            return rejectWithValue(error?.response?.data?.error || 'Something went wrong');
        }
    }
);

const verifySignup = createSlice({
    name:'verifySignup',
    initialState,
    reducers: {},
    extraReducers:(builder) => {
        builder.addCase(sendSignupRequest.pending, (state) => {
            state.loading = true;
            state.sendError = '';
            state.verifyError = '';
        });

        builder.addCase(sendSignupRequest.fulfilled, (state) => {
            state.loading = false;
            state.sendError = '';
            state.verifyError = '';
        });
          

         builder.addCase(sendSignupRequest.rejected, (state, action) => {
            state.loading = false;
            state.verifyError = '';
            state.sendError = action.payload as string || "Something went wrong"
        });

        builder.addCase(verifySignupRequest.pending, (state) => {
            state.loading = true;
            state.sendError = '';
            state.verifyError = '';
        });

        builder.addCase(verifySignupRequest.fulfilled, (state, action) => {
            state.loading = false;
            state.verifyError = '';
            state.sendError = '';
        
            const token = action.payload.token;
            localStorage.removeItem('authToken');
            sessionStorage.removeItem('authToken');
            
            if (localStorage.getItem('remember_me') === 'true') 
                localStorage.setItem('authToken', token);
            else
                sessionStorage.setItem('authToken', token);
            localStorage.removeItem('remember_me')
        });

         builder.addCase(verifySignupRequest.rejected, (state, action) => {
            state.loading = false;
            state.sendError = '';
            state.verifyError = action.payload as string || "Something went wrong"
        });       
    }

})

export default verifySignup.reducer