import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SignupState, SignupAction } from "../../../models/auth/RTKAuth";
import { postData } from "../../../api/crudServices";

const initialState:SignupState = {
    loading: false,
    error: ''
}

export const signupRequest = createAsyncThunk(
    'signup/signupRequest', 
    async ({ email, city_id, name, password, remember_me }: SignupAction, { rejectWithValue }) => {
        try {
            const res = await postData('user/register', { email, city_id, name, password });

            if (remember_me === 'true')
                localStorage.setItem('remember_me', 'true');
            else
                localStorage.setItem('remember_me', 'false');
    
            return res.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.error || 'Something went wrong';
            return rejectWithValue(errorMessage);
        }
    }
);

const signup = createSlice({
    name:'signup',
    initialState,
    reducers: {},
    extraReducers:(builder) => {
        builder.addCase(signupRequest.pending, (state) => {
            state.loading = true;
            state.error = '';
        })

        builder.addCase(signupRequest.fulfilled, (state) => {
            state.loading = false;
            state.error = '';
        });
          

         builder.addCase(signupRequest.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string || "Something went wrong"
        })
    }

})

export default signup.reducer