import { NavLink } from 'react-router-dom'
import './bookings.css'

function Bookings() {
  return (
    <>
        <h3 className='blueBox'>Your bookings</h3>
        <div className='centeredText'>
            <h2 className='header2'>See your bookings here</h2>
            <p className='secIntro'>Lorem ipsum dolor sit amet consectetur. Faucibus morbi elementum sollicitudin dignissim.</p>
        </div>
        <div className='bookingsLinks'>
            <NavLink className={({ isActive }) => (isActive ? 'activeBookingSec': '')} to='.' end>On queue</NavLink>
            <NavLink className={({ isActive }) => (isActive ? 'activeBookingSec': '')+' firstLink'} to='./completed'>Completed</NavLink>
            <NavLink className={({ isActive }) => (isActive ? 'activeBookingSec': '')} to='./cancelled'>Cancelled</NavLink>
        </div>
    </>
  )
}

export default Bookings