import { useParams } from 'react-router-dom';
import save from '../../assets/pics/saved.svg'
import like from '../../assets/pics/like.svg'
import './readMore.css';
import { ChangeEvent, useState } from 'react';
import Comment from './nestedComponents/comment/Comment';

function ReadMore() {
  const { id } = useParams();
  const [text, setText] = useState('')


  function handleInput(e: ChangeEvent<HTMLTextAreaElement>){
      const textarea = e.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
      setText(textarea.value);
  }

  return (
    <div className='readMore'>
      <div className="readMorePaper">
        <h2 className='header2'>Lorem ipsum dolor sit</h2>
        <p className='secIntro'>12 / 5 / 2024 By Dr. Reda Al-kweifati</p>
        {/*Here you put the page you fetch from the server*/}
        <div className='buttons'>
          <button className='reactButton'>
            <img src={like} alt='like'/>
          </button>
          <button className='reactButton'>
            <img src={save} alt='save'/>
          </button>
        </div>
      </div>

      <div className="comments">
        <div className="addAComment">
          <img src={like} alt="you" />
          <textarea value={text} onChange={(e)=>handleInput(e)} rows={1}
            name="addComment" id="addComment" placeholder='Add a comment...' className='addACommentInput hideScrollBar'
          ></textarea>
        </div>
        <Comment/>
        <Comment/>
        <Comment/>
        <Comment/>
      </div>
    </div>
  );
}

export default ReadMore;
