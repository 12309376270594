import toast from 'react-hot-toast'
import GenderSelect from '../nestedComponents/selects/GenderSelects';
import { useRef, useState } from 'react';
import PositionSelect from '../nestedComponents/selects/PositionSelect';
import dropFile from '../../../assets/pics/applyForm/dropFile.svg'
import './jobForm.css'

function JobForm() {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        gender_id: '',
        position_id: '',
        resume: null as File | null,
        description: '',
      });
    
      const fileInputRef = useRef<HTMLInputElement | null>(null);
    
      function handleInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | {name:string; value:string}) {
        const { name, value } = 'target' in e ? e.target : e;
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    
      function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0] || null;
        setFormData((prev) => ({ ...prev, resume: file }));
      }
    
      function handleDrop(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        const file = e.dataTransfer.files?.[0];
        if (file) {
          setFormData((prev) => ({ ...prev, resume: file }));
        }
      }
    
      function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
      }
    
      function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        try {
          toast.success('Succeeded', { duration: 3000 });
        } catch (err) {
          console.error('Submission error:', err);
          toast.error('Error', { duration: 3000 });
        }
      }
    
  return (
    <form className="jobForm" onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input type="text" name="name" id="name" value={formData.name} onChange={handleInputChange}/>
        <label htmlFor="phone">Phone</label>
        <input type="text" name="phone" id="phone" value={formData.phone} onChange={handleInputChange}/>
        <label htmlFor="email">Email</label>
        <input type="email" name="email" id="email" value={formData.email} onChange={handleInputChange}/>
        <label htmlFor="gender">Gender</label>
        <GenderSelect formData={formData} handleChange={handleInputChange}/>
        <label htmlFor="position">Position</label>
        <PositionSelect  formData={formData} handleChange={handleInputChange} options={[{name: 'Surgeon', id:'1'}, {name: 'Dentist', id:'2'}]}/>
        <label htmlFor="description">Description</label>
        <textarea name="description" id="description" rows={1} value={formData.description} onChange={handleInputChange} className="hideScrollBar"/>
        <label htmlFor="resume">Resume</label>
        <div className="uploadWra"
            onClick={() => fileInputRef.current?.click()}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <input ref={fileInputRef} type="file" id="resume" name="resume" onChange={handleFileChange} accept=".pdf,.doc,.docx"/>
            <img src={dropFile} alt="dropFile"/>
            <p>{formData.resume ? formData.resume.name : 'Click or drag a file to this area to upload'}</p>
        </div>
        <button type="submit" disabled={!(formData.resume && formData.description && formData.email && formData.gender_id && formData.name && formData.phone && formData.position_id)}>Submit</button>
    </form>
  );
}
    
    export default JobForm;