import { useState } from 'react'
import day from '../../../../assets/pics/bookAService/day.svg'
import afternoon from '../../../../assets/pics/bookAService/afternoon.svg'
import night from '../../../../assets/pics/bookAService/night.svg'
import './chooseDate.css'
import { ChooseDateProps } from '../../../../models/bookAService'
import toast from 'react-hot-toast'

export default function ChooseDate(props: ChooseDateProps) {
  const {setStep, setAppointment, appointment} = props

  const dates = [
    { day: 'sat', date: '16/11/2024' },
    { day: 'sun', date: '17/11/2024' },
    { day: 'mon', date: '18/11/2024' },
    { day: 'sat', date: '16/11/2024' },
    { day: 'sun', date: '17/11/2024' },
    { day: 'mon', date: '18/11/2024' },
  ]

  const timeSlots = [
    { label: 'Daytime', icon: <img src={day} className="w-4 h-4"/>, times: ['9:00 AM - 10:00 AM', '9:00 AM - 10:00 AM'] },
    { label: 'Afternoon', icon: <img src={afternoon} className="w-4 h-4"/>, times: ['9:00 AM - 10:00 AM', '9:00 AM - 10:00 AM'] },
    { label: 'Nighttime', icon: <img src={night} className="w-4 h-4"/>, times: ['9:00 AM - 10:00 AM', '9:00 AM - 10:00 AM'] },
  ]

  return (
    <div className="chooseDate hideScrollBar step">
      <h4 className="importantText">choose date and time</h4>

      <div className="dateSelector hideScrollBar">
        <div className="datesWra hideScrollBar">  
          {dates.map(({ day, date }, i) => (
            <button
              key={date+i}
              className={`${appointment.day === date ? 'text-dodger-blue border-dodger-blue' : 'text-[#737377]'} dateSelectButton`}
              onClick={(e) =>setAppointment({name:'day',value:date})}>
              <span>{day} {date}</span>
            </button>
          ))}
        </div>
      </div>

      <div className="timeSlots">
        {timeSlots.map(({ label, icon, times }) => (
          <div key={label} className="timeSlot">
            <div className="label">
              {icon}
              <span>{label}</span>
            </div>
            <div className="timesGrid">
              {times.map((time, index) => (
                <button
                  key={`${time}-${index}`}
                  onClick={(e) =>     setAppointment({name:'time',value:time})
}      className={`timeSelectButton
                    ${appointment.time === time
                      ? 'bg-dodger-blue text-white'
                      : ' text-blue-500'
                    }`}
                >
                  {time}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>

      <button 
        className="confirmButton"
        onClick={() => {
          if(!appointment.day)
            toast.error('Choose a day', {duration: 3000})
          else if(!appointment.time)
            toast.error('Choose a suitable time', {duration: 3000})
          else 
            setStep(pre => pre+1)
        }}
      >
        Next
      </button>
    </div>
  )
}