import React from 'react'
import doctor from '../../../assets/pics/homepage/doctor.webp'
import shield from '../../../assets/pics/homepage/shield.svg'
import plus from '../../../assets/pics/homepage/plus.svg'
import './ourVision.css'
import { Link } from 'react-router-dom'

function OurVision() {
  return (
    <div className='ourVision paper'>
        <img src={doctor} alt='doctor' className='doctorIm'/>
        <div className='ourVisionText'>
            <h3 className='blueBox'>Our vision</h3>
            <div>
                <h2 className='header2'>Your health in safe hands</h2>
                <p className='secIntro'>Lorem ipsum dolor sit amet consectetur. Faucibus morbi elementum sollicitudin dignissim.</p>
            </div>
            <div className='ourVisionSecs'>
                <div className='ourVisionSec'>
                    <img className='ourVisionIcon' alt="ic" src={shield}/>
                    <span className='secondaryTitle'>Lorem ipsum dolor sit amet consectur.</span>                    
                    <p>Lorem ipsum dolor sit amet consectetur. Vitae velit purus dignissim at quam volutpat. Ac sagittis cursus rhoncus ut urna orci. Sit ac pellentesque nascetur tempor urna nec. Nibh a nullam sollicitudin et ac lacus. Volutpat nibh laoreet fames scelerisque elementum mauris ornare in neque</p>
                </div>
                <div className='ourVisionSec'>
                    <img className='ourVisionIcon' alt="ic" src={plus}/>
                    <span className='secondaryTitle'>Lorem ipsum dolor sit amet consectur.</span>
                    <p>Lorem ipsum dolor sit amet consectetur. Vitae velit purus dignissim at quam volutpat. Ac sagittis cursus rhoncus ut urna orci. Sit ac pellentesque nascetur tempor urna nec. Nibh a nullam sollicitudin et ac lacus. Volutpat nibh laoreet fames scelerisque elementum mauris ornare in neque</p>
                </div>
            </div>
            <Link to='/' className='bgBlue seeServices'>See our services</Link>
        </div>
    </div>
  )
}

export default OurVision