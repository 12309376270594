import person from '../../../../assets/pics/blog/person.png'
import './writerCard.css'

function WriterCard() {
  return (
    <div className='writerCard'>
      <img src={person} alt='writer' className='writerImage'/>
      <div>
        <h4 className='mt-2'>Ahmad Alhasany</h4>
        <p className='profession'>Computer Engineer</p>
      </div>
    </div>
  )
}

export default WriterCard