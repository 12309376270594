import './comment.css'

function Comment() {
  return (
    <div className='comment'>
        <div className='commentTag'>
            <img src="" alt="pic"/>
            <div>
                <span>Ahmad Alhasany</span>
                <p className='duration'>11 months</p>
                <p className='commentContent'>Lorem ipsum dolor sit amet consectetur. Ut venenatis nisl lectus posuere libero. Elementum gravida integer turpis euismod nulla mi.</p>
            </div>
        </div>
    </div>
  )
}

export default Comment