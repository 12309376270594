import TopSection from '../../components/homepage/topSection/TopSection'
import OurVision from '../../components/homepage/ourVision/OurVision'
import OurServices from '../../components/homepage/ourServices/OurServices'
import './homepage.css'
import TopProviders from '../../components/homepage/topProviders/TopProviders'
import Blog from '../../components/homepage/blog/Blog'
import Rating from '../../components/homepage/rating/Rating'
import { useEffect, useState } from 'react'

function Homepage() {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 795);

  useEffect(() => {
    const handleResize = () => setIsWideScreen(window.innerWidth > 795);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='homepage'>
        <TopSection/>
        <OurVision/>
        <OurServices/>
        <TopProviders isWideScreen={isWideScreen}/>
        <Blog/>
        <Rating isWideScreen={isWideScreen}/>
    </div>
  )
}

export default Homepage