import './departmentInfo.css'

function DepartmentInfo() {
  return (
    <div className="departmentInfo">
        <div className="blueCon headInfo">
            <div className='jobHeadlines'>
                <span className='importantText'>Department</span>
                <span className='importantText'>Something</span>
            </div>
            <div className='jobHeadlines'>
                <span className='importantText'>Vacancy location</span>
                <span className='importantText'>Damascus</span>
            </div>
        </div>
        <div className="blueCon">
        <span className='importantText'>Job duties</span>
        <ol>
            <li>Lorem ipsum dolor sit amet consectetur. Nam turpis arcu dictum id lobortis. Dictum morbi consequat faucibus dui ipsum potenti habitasse. Ultricies velit aliquet mauris faucibus tortor in lorem senectus. Ut ac sit dictumst elementum. Augue.</li>
            <li>Lorem ipsum dolor sit amet consectetur. Nam turpis arcu dictum id lobortis. Dictum morbi consequat faucibus dui ipsum potenti habitasse. Ultricies velit aliquet mauris faucibus tortor in lorem senectus. Ut ac sit dictumst elementum. Augue.</li>
            <li>Lorem ipsum dolor sit amet consectetur. Nam turpis arcu dictum id lobortis. Dictum morbi consequat faucibus dui ipsum potenti habitasse. Ultricies velit aliquet mauris faucibus tortor in lorem senectus. Ut ac sit dictumst elementum. Augue.</li>
            <li>Lorem ipsum dolor sit amet consectetur. Nam turpis arcu dictum id lobortis. Dictum morbi consequat faucibus dui ipsum potenti habitasse. Ultricies velit aliquet mauris faucibus tortor in lorem senectus. Ut ac sit dictumst elementum. Augue.</li>
            <li>Lorem ipsum dolor sit amet consectetur. Nam turpis arcu dictum id lobortis. Dictum morbi consequat faucibus dui ipsum potenti habitasse. Ultricies velit aliquet mauris faucibus tortor in lorem senectus. Ut ac sit dictumst elementum. Augue.</li>
        </ol>
        </div>
        <div className="blueCon requirments">
        <div>
            <span>Education</span>
            <p>haehf uehfsif feskdf fehufhsdf</p>
        </div>
        <div>
            <span>Knowledge, skills and abilities</span>
            <p>haehf uehfsif feskdf fehufhsdf</p>
        </div>
        <div>
            <span>Experience</span>
            <p>haehf uehfsif feskdf fehufhsdf</p>
        </div>
        </div>
    </div>
  )
}

export default DepartmentInfo