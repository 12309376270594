import ServiceCard from '../../sharedComponents/serviceCard/ServiceCard'
import food from '../../../assets/pics/homepage/food.webp'
import './blog.css'

function Blog() {
    const buttonContent = 'Read me'
  return (
    <div className='homeBlog'>
        <h3 className='blueBox'>Blog</h3>
        <div className='centeredText'>
            <h2 className='header2'>Latest health and medical news </h2>
            <p className='secIntro'>Lorem ipsum dolor sit amet consectetur. Faucibus morbi elementum sollicitudin dignissim.</p>
        </div>
        <div className='cardsWra'>
            <ServiceCard buttonContent={buttonContent} image={food} to='/blog/2'/>
            <ServiceCard buttonContent={buttonContent} image={food} to='/blog/2'/>
            <ServiceCard buttonContent={buttonContent} image={food} to='/blog/2'/>
        </div>
    </div>
  )
}

export default Blog