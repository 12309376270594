import { ChooseADoctorProps } from '../../../../models/bookAService'
import AppointmentCard from '../appointmentCard/AppointmentCard'
import './chooseADoctor.css'

function ChooseADoctor(props: ChooseADoctorProps) {
  const {setStep, setAppointment} = props;

  function handleClick(e:  React.MouseEvent<HTMLButtonElement>){
    const doc = e.currentTarget.innerText
    setAppointment(pre=>{return {...pre, doctor:doc}})
    setStep(pre => pre+1);
  }
  
  return (
    <div className='chooseADoctor hideScrollBar step'>
      <h4 className='importantText'>Choose your Doctor</h4>
      <button onClick={(e)=>handleClick(e)}>
        <AppointmentCard hideDateBar/>
      </button>
      <button onClick={(e)=>handleClick(e)}>
        <AppointmentCard hideDateBar/>
      </button>
      <button onClick={(e)=>handleClick(e)}>
        <AppointmentCard hideDateBar/>
      </button>
      <button onClick={(e)=>handleClick(e)}>
        <AppointmentCard hideDateBar/>
      </button>
      <button onClick={(e)=>handleClick(e)}>
        <AppointmentCard hideDateBar/>
      </button>
      <button onClick={(e)=>handleClick(e)}>
        <AppointmentCard hideDateBar/>
      </button>
    </div>
  )
}

export default ChooseADoctor