import { useState, useEffect } from 'react';
import Logo from '../logo/Logo';
import Search from '../../appBar/search/Search';
import Location from '../../appBar/location/Location';
import LanguageToggle from '../../appBar/languageToggle/LanguageToggle';
import { NavLink, Link } from 'react-router-dom';
import { authCheck } from '../../../utils/functions/authCheck';
import DropDown from '../../appBar/dropDown/DropDown';
import './appBar.css';

function AppBar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const links = ['Homepage', 'Blog','Apply for a job'];

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const top = document.documentElement.scrollTop  ,currentScroll = top - lastScrollTop;
      if (currentScroll > 0)
        setIsScrolled(true);
      else if(currentScroll < -3)
        setIsScrolled(false);
      lastScrollTop = top <= 0 ? 0 : top;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`appBarPC`}>
      <div className='upperPart'>
        <Logo color='white' />
        <Location />
        <Search />
        <LanguageToggle />
        {
          authCheck() ? 
            <DropDown userName='Ahmad'/>
            :<Link to='./login'>Login</Link>  
        }
      </div>
      <div className={`lowerPart ${isScrolled && 'translate'}`}>
        <ul className='routes'>
          {links.map((link, index) => (
            <li key={index}>
              <NavLink 
                className={({ isActive }) => (isActive ? 'activeLink' : '')}      
                to={link === "Homepage" ? "/" : `/${link.replace(/\s+/g, '').toLowerCase()}`}
              >
                {link}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

export default AppBar;
