import { Link, NavLink, useNavigate } from 'react-router-dom';
import { DrawerProps } from '../../../models/AppBar'
import Logo from '../../sharedComponents/logo/Logo';
import './drawer.css'
import LanguageToggle from '../languageToggle/LanguageToggle';
import Location from '../location/Location';
import person from '../../../assets/pics/appBar/person.svg'
import job from '../../../assets/pics/appBar/job.svg'
import { authCheck } from '../../../utils/functions/authCheck';

function Drawer(props: DrawerProps) {
    const {open, toggle} = props, navigate = useNavigate();
    const drawerListStyles = !open ? '-translate-x-full' :'', togglerStyles = open ? 'bg-opacity-10' : 'bg-opacity-0'
    const links = ['Homepage', 'Blog', 'Apply for a job'];


    return (
    <div className={`drawer ${!open ? 'pointer-events-none' :''}`}>
        <div className={`drawerList ${drawerListStyles}`}>
            <div className='paddingoWra'>
                <Logo color='white'/>
            </div>
            <ul className='drawerRoutes'>
                {links.map((link, index) => (
                    <li key={index}>
                        <NavLink 
                            className={({ isActive }) => (isActive ? 'activeLink' : '')}
                            onClick={toggle}
                            to={link === "Homepage" ? "/" : `/${link.replace(/\s+/g, '').toLowerCase()}`}
                        >
                        {link}
                        </NavLink>
                    </li>
                ))}
                <div className='bottomBorder'></div>
            </ul>
            <div className="paddingoWra">
                <LanguageToggle/>
            </div>
            <div className="locationWra">
                <Location/>
            </div>
            <div className='loggedInRoute'>
                <img src={person} alt="person" />
                <Link to="profile">Profile</Link>
            </div>
            {authCheck() ? 
            <button className='drawerLogoutButton'
                onClick={() => {
                    navigate('/login')
                    localStorage.removeItem('authToken')
                }}>
                Log out
            </button> : <Link to='./login' className='drawerLoginButton'>Log in</Link>}
        </div>
        <div className={`toggler bg-black ${togglerStyles}`} onClick={toggle}></div>
    </div>
  )
}

export default Drawer

/**
 right above authCheck() :
    <div className='loggedInRoute'>
        <img src={job} alt="job" />
        <Link to="applyforajob">Apply for a job</Link>
    </div>
 */