import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();
  const previousPath = useRef(pathname);

  useEffect(() => {
    const isProfileNavigation =
      previousPath.current.startsWith('/profile') &&
      pathname.startsWith('/profile');

    if (!isProfileNavigation) {
      window.scrollTo(0, 0);
    }

    previousPath.current = pathname;
  }, [pathname]);

  return null;
}

export default ScrollToTop;
