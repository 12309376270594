import avatar from '../../../../assets/pics/homepage/food.webp'
import calendar from '../../../../assets/pics/calendar.svg'
import clock from '../../../../assets/pics/bookAService/clock.svg'
import './appointmentCard.css'
import { AppointmentCardProps } from '../../../../models/bookAService'

function AppointmentCard(props:AppointmentCardProps) {
    const {hideDateBar = false} = props
  return (
    <div className='appointmentCard'>
        <div className="doctorInfo">
            <img src={avatar} alt='doctor'/>
            <div>
                <span>Dr. Reda Al-kweifati</span>
                <p>Lorem ipsum dolor sit amet consectet.</p>
            </div>
        </div>
        {!hideDateBar && <div className="dateInfo">
            <div>
                <img src={calendar} alt='icon'/>
                <span>sun 12 / 5 / 2024</span>
            </div>
            <div>
                <img src={clock} alt='icon'/>
                <span>9:00 AM - 10:00 AM</span>
            </div>
        </div>}
    </div>
  )
}

export default AppointmentCard