import ServiceCard from '../../sharedComponents/serviceCard/ServiceCard'
import patient from '../../../assets/pics/homepage/patient.webp'
import './ourServices.css'

function OurServices() {
  const buttonContent = 'Check it out'
  return (
    <div className='ourServicesSec'>
        <h3 className='blueBox'>Our services</h3>
        <div className='centeredText'>
            <h2 className='header2'>Your life our responsibility</h2>
            <p className='secIntro'>Lorem ipsum dolor sit amet consectetur. Faucibus morbi elementum sollicitudin dignissim.</p>
        </div>
        <div className='cardsWra'>
            <ServiceCard buttonContent={buttonContent} image={patient} to='/ourservices/2'/>
            <ServiceCard buttonContent={buttonContent} image={patient} to='/ourservices/2'/>
            <ServiceCard buttonContent={buttonContent} image={patient} to='/ourservices/2'/>
        </div>
    </div>
  )
}

export default OurServices