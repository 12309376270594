import { useState } from 'react'
import AddAService from '../nestedComponents/addAService/AddAService';
import ChooseAService from '../nestedComponents/chooseAService/ChooseAService';
import ChooseADoctor from '../nestedComponents/chooseADoctor/ChooseADoctor';
import ChooseDate from '../nestedComponents/chooseDate/ChooseDate';
import CheckInfo from '../nestedComponents/checkInfo/CheckInfo';
import Pay from '../nestedComponents/pay/Pay';
import './booking.css'
import { AppointmentState } from '../../../models/bookAService';
import { useSearchParams } from 'react-router-dom';

function Booking() {
  const [searchParams, setSearchParams] = useSearchParams();
  const doctorId = searchParams.get('DI');
  const [step, setStep] = useState(doctorId ? 2 : 0); 
  const [appointment, setAppointment] = useState<AppointmentState>({ service:'', doctor:'', day:'', time:'', paymentMethod:'' });

  function handleEnd(){
    console.log('hey')
    setStep(0);
    setAppointment({ service:'', doctor:'', day:'', time:'', paymentMethod:'' });
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.delete('DI');
    setSearchParams(updatedSearchParams);
    }

  function displayedStep(){
    switch(step){
      case 0: return <AddAService setStep={setStep}/>;
      case 1: return <ChooseADoctor setStep={setStep} setAppointment={setAppointment}/>;
      case 2: return <ChooseDate setStep={setStep} setAppointment={(e:{name:string, value:string})=>{
        setAppointment(pre=>{return {...pre, [e.name]:e.value}})
      }} appointment={appointment}/>;
      case 3: return <CheckInfo setStep={setStep}/>;
      case 4: return <Pay setStep={setStep} setAppointment={setAppointment} appointment={appointment}/>;
      default: handleEnd()
    }
  }

  
    return (
    <div className="booking">
      {displayedStep()}
    </div>
  )
}

export default Booking