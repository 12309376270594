import ProviderCard from '../nestedComponents/providerCard/ProviderCard'
import './topProviders.css'

function TopProviders({isWideScreen}:{isWideScreen:boolean}) {

  return (
    <div className='topProviders paper'>
      <h3 className='blueBox'>Top providers</h3>
      <div className='centeredText'>
        <h2 className='header2'>Some of our best providers</h2>
        <p className='secIntro'>
          Lorem ipsum dolor sit amet consectetur. Faucibus morbi elementum sollicitudin dignissim.
        </p>
      </div>
      <div className='cardsWra'>
        <ProviderCard />
        <ProviderCard />
        <ProviderCard />
        {isWideScreen && (
          <>
            <ProviderCard />
            <ProviderCard />
            <ProviderCard />
          </>
        )}
      </div>
    </div>
  )
}

export default TopProviders
