import avatar from '../../../../assets/pics/homepage/avatar.webp'
import './review.css'

function Review() {
  return (
    <div className='review'>
        <img src={avatar} alt='pic'/>
        <div>
            <h4>Ahmad Alhasany</h4>
            <p className='profession'>Computer Engineer</p>
            <p>Lorem ipsum dolor sit amet consectetur. Vitae velit purus dignissim at quam volutpat. Ac sagittis cursus rhoncus ut urna orci.</p>
        </div>
    </div>
  )
}

export default Review