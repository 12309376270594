import { Link } from 'react-router-dom'
import avatar from '../../../assets/pics/homepage/doctor.webp'
import './jobCard.css'

function JobCard() {
  return (
    <div className='jobCard'>
        <Link to='./2'>
            <img src={avatar} alt='doctor'/>
        </Link>
        <div className='jobCardDes'>
          <span>Lorem ipsum dolor sit amet consectur.</span>
          <p>Lorem ipsum dolor sit amet consectetur. Vitae velit purus dignissim at quam volutpat. Ac sagittis cursus rhoncus ut urna orci. Sit ac pellentesque nascetur tempor urna nec</p>
            <div className='jobSerWra'>
                <p className='jobSer'>service 1</p>
                <p className='jobSer'>service 1</p>
                <p className='jobSer'>service 1</p>
            </div>  
        </div>
    </div>
  )
}

export default JobCard