import { ChangeEvent, useState } from 'react';
import BookingsCard from '../bookingsCard/BookingsCard'
import happy from '../../../../assets/pics/profile/happy.svg'
import neutral from '../../../../assets/pics/profile/neutral.svg'
import sad from '../../../../assets/pics/profile/sad.svg'
import './completed.css'
import SetStarRating from '../../../bookAService/nestedComponents/starRating/SetStarRating';

function Completed() {
  const [openModalId, setOpenModalId] = useState(0);
  const [data, setData] = useState({rating: 3, comment: ''});

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>|{name:string, value: number}) => {
    const {name, value} = 'target' in e ? e.target : e;
    setData(pre => {return {...pre, [name]: value}});
  }
  const handleOpenModal = (id:number) => {
    setOpenModalId(id);
  };
  const handleCloseModal = () => {
    setData({comment:'', rating: 3});
    setOpenModalId(0);
  };
  
  return (
    <div className='onQueue bookingsSec'>
      {Array.from({ length: 4 }, (_, index) => {
        const id = index + 1;

        return (
          <BookingsCard key={id}>
            <button
              className="rateYourAppointment"
              onClick={() => handleOpenModal(id)}
            >
              Rate your appointment
            </button>
            {openModalId === id && (
              <div className="modalBackdrop" onClick={handleCloseModal}>
                <div className="modal" onClick={(e) => e.stopPropagation()}>
                  <span className='importantText'>Rate your appointment</span>
                  <img src={data.rating <= 2 ? sad : data.rating > 3 ? happy : neutral} alt="happy" />
                  <SetStarRating rating={data.rating} setRating={handleChange}/>
                  <span className='importantText'>Leave a comment</span>
                  <textarea value={data.comment} name='comment' onChange={handleChange} placeholder='Your comment'/>
                  <button disabled={!data.comment} onClick={()=>setOpenModalId(0)}>Submit</button>
                </div>
              </div>
            )}
          </BookingsCard>
        );
      })}
    </div>
  )
}

export default Completed