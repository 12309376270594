import React from 'react'
import doctor from '../../../../assets/pics/auth/doctor.webp'
import './authImage.css'

function AuthImage() {
  return (
    <div className='doctorWra'>
      <img src={doctor} alt='doctor' className='doctor'/>
    </div>
  )
}

export default AuthImage