import { BookingsCardProps } from '../../../../models/bookAService'
import avatar from '../../../../assets/pics/homepage/food.webp'
import calendar from '../../../../assets/pics/calendar.svg'
import clock from '../../../../assets/pics/bookAService/clock.svg'
import './bookingsCard.css'

function BookingsCard(props:BookingsCardProps) {
    const {children} = props
  return (
    <div className='bookingsCard'>
      <div className='bookingsFlexWra'>
        <img src={avatar} alt='doctor'/>
        <div>
          <span>Dr. Reda Al-kweifati</span>
          <p>Lorem ipsum dolor sit amet consectet.</p>
          <div className="bookingsDateInfo">
            <div>
              <img src={calendar} alt='icon'/>
              <span>sun 12 / 5 / 2024</span>
            </div>                  
            <div>
              <img src={clock} alt='icon'/>
              <span>9:00 AM - 10:00 AM</span>
            </div>  
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}

export default BookingsCard