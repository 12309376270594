import RatingBar from '../nestedComponents/ratingBar/RatingBar'
import RatingCard from '../nestedComponents/ratingCard/RatingCard'
import StarRating from '../nestedComponents/starRating/StarRating'
import './ratingWra.css'

function RatingWra() {
  return (
    <div className='ratingWra hideScrollBar'>
      <div className="ratingWraOverview">
        <div className='generalOverview'>
          <h2 className='header2'>4,5</h2>
          <StarRating rating={4.5} h='25' w='25'/>
          <p>(123 reviews)</p>
        </div>
        <div className="verticalSeperator"></div>
        <div className='detailedOverview'>
          <RatingBar percentage={90} indicator='5'/>
          <RatingBar percentage={30} indicator='4'/>
          <RatingBar percentage={50} indicator='3'/>
          <RatingBar percentage={80} indicator='2'/>
          <RatingBar percentage={100} indicator='1'/>
        </div>
      </div>
      <div className='seperate'></div>
      <RatingCard/>
      <div className='seperate'></div>
      <RatingCard/>
      <div className='seperate'></div>
      <RatingCard/>
    </div>
  )
}

export default RatingWra